import { db, firebase } from "./firebase_.js";

export function createUser(user) {
  const email =
    user.email || user.toJSON().providerData[0].email || user.displayName;

  return db.collection("users").doc(user.uid).set(
    {
      uid: user.uid,
      email: email,
      tier: "alpha",
      marked_for_deletion_date: null,
      active: true,
    },
    { merge: true }
  );
}

export function getUser(uid) {
  return db
    .collection("users")
    .doc(uid)
    .get()
    .then((snapshot) => {
      return snapshot.data();
    });
}

export function markForDeletion(userId) {
  const data = {
    marked_for_deletion_date: firebase.firestore.Timestamp.now(),
  };
  return db.collection("users").doc(userId).update(data);
}

export function clearMarkForDeletion(userId) {
  const data = {
    marked_for_deletion_date: null,
  };

  return db.collection("users").doc(userId).update(data);
}

export function getUserDoc(userId) {
  return db
    .collection("items")
    .where("owner", "==", userId)
    .get()
    .then((snapshot) => snapshot.docs)
    .then((docs) => {
      if (docs.length === 0) {
        return createItem(userId);
      } else {
        return docs[0];
      }
    });
}

export function createBackup(docId, data, timestamp) {
  if (!data.contents) return;

  return getLastBackup(docId).then((obj) => {
    if (obj && obj.data().contents === data.contents) {
      return;
    } else {
      if (!timestamp) {
        timestamp = new Date().toISOString();
      }
      return db
        .collection("items")
        .doc(docId)
        .collection("backups")
        .doc(timestamp)
        .set(data);
    }
  });
}

function getLastBackup(docId) {
  // By default, firestore returns documents in ascending order by id
  return db
    .collection("items")
    .doc(docId)
    .collection("backups")
    .limit(1)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        return null;
      } else {
        return snapshot.docs[0];
      }
    });
}

export function getBackups(docId) {
  return db
    .collection("items")
    .doc(docId)
    .collection("backups")
    .get()
    .then((snapshot) => snapshot.docs);
}

// function createUser() {
//   return;
// }

function createItem(userId) {
  const data = {
    contents: "",
    owner: userId,
    local_ts: 0,
    backup_ts: null,
    server_ts: firebase.firestore.Timestamp.now(),
  };

  return db.collection("items").add(data);
}

export function listenToItemUpdates(docId, onUpdate, onError) {
  return db
    .collection("items")
    .doc(docId)
    .onSnapshot(
      { includeMetadataChanges: true },
      (snapshot) => {
        if (snapshot.metadata.fromCache) return false;
        if (snapshot.metadata.hasPendingWrites) return false;

        onUpdate(snapshot.data());
      },
      (error) => {
        onError && onError(error);
      }
    );
}

export function updateItem(docId, data) {
  data = {
    ...data,
    backup_ts: null,
    server_ts: firebase.firestore.Timestamp.now(),
  };

  return db.collection("items").doc(docId).update(data);
}
