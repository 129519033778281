import { firebase } from "./firebase_.js";
import * as notebook from "./notebook.js";

function listenForOnlineStatus() {
  function updateOnlineStatus(event) {
    const el = document.getElementById("online-status");
    if (el) {
      if (navigator.onLine) {
        el.className = "status-good";
        el.innerHTML = "online ✔️ ";
      } else {
        el.className = "status-bad";
        el.innerHTML = "offline ❌";
      }
    }
    updateServerStatusDisplay();
    updateSyncStatusDisplay();
  }

  window.addEventListener("online", updateOnlineStatus);
  window.addEventListener("offline", updateOnlineStatus);
  updateOnlineStatus();
}

let IS_LOGGED_IN = false;
function listenForAuthStatus() {
  if (firebase.apps.length <= 0) {
    setTimeout(listenForAuthStatus, 100);
  } else {
    firebase.auth().onAuthStateChanged(function (user) {
      const el = document.getElementById("user-status");
      IS_LOGGED_IN = !!user;
      if (el) {
        if (IS_LOGGED_IN) {
          el.innerHTML = user.toJSON().email || user.toJSON().name;
          el.className = "status-good";
        } else {
          el.innerHTML =
            '<a href="/signin.html">sign in or create an account</a>';
          el.className = "status-bad";
        }
      }

      // const accountEl = document.getElementById("account-link");
      const signinEl = document.getElementById("signin-link");
      const signinCTAEl = document.getElementById("signup-prompt");
      const userTextEl = document.getElementById("user-text");
      if (IS_LOGGED_IN) {
        signinEl.href = "./account.html";
        signinCTAEl.style.display = "none";
        userTextEl.style.display = "unset";
        userTextEl.innerHTML = `Welcome back ${
          user.toJSON().email || user.toJSON().name || user.toJSON().displayName
        }!`;
        // accountEl.style.display = "unset";
        // signinEl.style.display = "none";
      } else {
        signinEl.href = "./signin.html";
        signinCTAEl.style.display = "unset";
        userTextEl.style.display = "none";
        userTextEl.innerHTML = "";
        // accountEl.style.display = "none";
        // signinEl.style.display = "unset";
      }
    });
  }
}

function listenForServerStatus() {
  updateServerStatusDisplay();
  setTimeout(listenForServerStatus, 500);
}

function updateServerStatusDisplay() {
  const el = document.getElementById("server-status");
  let newValue;
  let newClass;
  if (navigator.onLine && notebook.DB_LISTENER_ACTIVE) {
    newValue = "connected to server? ✔️";
    newClass = "status-good";
  } else {
    newValue = "connected to server? ❌";
    newClass = "status-bad";
  }

  if (!IS_LOGGED_IN) newClass = "status-disabled";

  if (el) {
    if (el.innerHTML !== newValue) el.innerHTML = newValue;
    if (el.className !== newClass) el.className = newClass;
  }
}

function listenForSyncStatus() {
  updateSyncStatusDisplay();
  setTimeout(listenForSyncStatus, 500);
}

function updateSyncStatusDisplay() {
  const el = document.getElementById("sync-status");
  let newValue;
  let newClass;

  // Ahead of server
  if (notebook.CURRENT_DOC_TS > notebook.LAST_SERVER_RECORD_TS) {
    // Pending writes to server
    if (navigator.onLine && IS_LOGGED_IN && notebook.PENDING_WRITE_TO_SERVER) {
      newValue = "synced to server? 🕒";
      newClass = "status-pending";
    } else {
      newValue = "synced to server? ❌";
      newClass = "status-bad";
    }
  } else {
    newValue = "synced to server? ✔️";
    newClass = "status-good";
  }

  if (!IS_LOGGED_IN) newClass = "status-disabled";

  if (el) {
    if (el.innerHTML !== newValue) el.innerHTML = newValue;
    if (el.className !== newClass) el.className = newClass;
  }
}

function initialiseStatusInfo() {
  listenForOnlineStatus();
  listenForServerStatus();
  listenForSyncStatus();
  listenForAuthStatus();
}

export { initialiseStatusInfo };
