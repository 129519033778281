import { initialiseFirebase, firebase } from "./firebase_.js";
import { getBackups, getUserDoc, createBackup, updateItem } from "./dbApi.js";
import { saveToCache } from "./notebook.js";
import * as utils from "./utils.js";

let DOC_ID = null;
let BACKUPS = [];
let USER_ID = null;

function getLocalStorageBackups() {
  return Object.keys(localStorage)
    .filter((key) => key.startsWith("backup"))
    .sort((a, b) => (a > b ? -1 : 1))
    .map((key) => {
      try {
        return {
          id: key.split("|")[2],
          doc: JSON.parse(localStorage.getItem(key)),
        };
      } catch {
        return null;
      }
    })
    .filter((x) => !!x);
}

function initialiseBackups() {
  BACKUPS = [
    ...getLocalStorageBackups().map((backup) => [backup.id, backup.doc]),
  ];

  const revisionSlider = document.getElementById("revision-slider");

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      USER_ID = user.uid;
      getUserDoc(USER_ID)
        .then((doc) => {
          DOC_ID = doc.id;
          return getBackups(DOC_ID);
        })
        .then((backups) => {
          // BACKUPS =
          const serverBackups = backups.map((backup) => [
            backup.id,
            backup.data(),
          ]);

          BACKUPS.push(...serverBackups);
          filterBackups();

          revisionSlider.min = 0;
          revisionSlider.max = BACKUPS.length - 1;
          revisionSlider.value = BACKUPS.length - 1;

          // Initialise the ui with the latest backup
          const [id, backup] = BACKUPS[BACKUPS.length - 1];
          displayBackup(id, backup);
        });
    } else {
      filterBackups();
      revisionSlider.min = 0;
      revisionSlider.max = BACKUPS.length - 1;
      revisionSlider.value = BACKUPS.length - 1;

      // Initialise the ui with the latest backup
      const [id, backup] = BACKUPS[BACKUPS.length - 1];
      displayBackup(id, backup);
    }
  });
}

function filterBackups() {
  if (BACKUPS.length <= 1) return;

  BACKUPS.sort((a, b) => (a[0] > b[0] ? 1 : -1));

  // Filter out adjacent backups with same content
  BACKUPS = BACKUPS.filter((backup, idx, backups) => {
    if (idx === 0) {
      return true;
    } else {
      return backup[1]?.contents !== backups[idx - 1][1]?.contents;
    }
  });
}

function displayBackup(id, backup) {
  const revisionList = document.getElementById("revision-container");

  const contents = backup.contents;

  const restoredFrom = id.replace("T", " ").replace("Z", "(UTC)");
  const newHtml = `

<h2>${restoredFrom}</h2>
<div class="revision-card sheet">
  <p class="content-preview">${contents}</p>
</div>
<button id="restore-revision-btn">Restore this version</button>
`;

  revisionList.innerHTML = newHtml;

  document.getElementById("restore-revision-btn").onclick = (evt) => {
    // console.log("RESTORE", id, backup);
    restoreBackup(id, backup);
  };
}

function restoreBackup(id, backup) {
  getUserDoc(USER_ID).then((doc) => {
    const docId = doc.id;
    const newBackup = { ...doc.data() };
    newBackup.local_ts = Date.now();
    createBackup(docId, newBackup);

    const restoredDoc = { ...backup };
    restoredDoc.local_ts = Date.now();
    restoredDoc.contents = `[Restored from: ${id}]\n\n` + restoredDoc.contents;

    updateItem(docId, restoredDoc).then((x) => {
      saveToCache(restoredDoc);
      window.location.replace("/");
    });
  });
}

function initialiseSlider() {
  const revisionSlider = document.getElementById("revision-slider");

  revisionSlider.oninput = () => {
    const [id, backup] = BACKUPS[revisionSlider.value];
    displayBackup(id, backup);
  };
}

export function onload() {
  utils.initResizeListener();
  initialiseFirebase();
  initialiseBackups();
  initialiseSlider();
}
