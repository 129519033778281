import { initialiseFirebase, firebase } from "./firebase_.js";

import * as dbApi from "./dbApi";

const LOCAL_STORAGE_KEY = "notepad.guru-cache";

function clearCache() {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  const backupKeys = Object.keys(localStorage).filter((key) =>
    key.startsWith("backup")
  );
  backupKeys.forEach((key) => localStorage.removeItem(key));
}

function sendPasswordReset() {
  const email = firebase.auth().currentUser.email;
  // [START sendpasswordemail]
  firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(function () {
      // Password Reset Email Sent!
      // [START_EXCLUDE]
      alert("Password Reset Email Sent!");
      // [END_EXCLUDE]
    })
    .catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // [START_EXCLUDE]
      if (errorCode == "auth/invalid-email") {
        alert(errorMessage);
      } else if (errorCode == "auth/user-not-found") {
        alert(errorMessage);
      }
      console.log(error);
      // [END_EXCLUDE]
    });
  // [END sendpasswordemail];
}

function signout() {
  firebase
    .auth()
    .signOut()
    .then(() => clearCache()); // TODO: confirm cache clears before redirect
}

// function deleteAccount() {
//   var r = confirm(
//     `If you proceed, your account will be marked pending for deletion for 1 week, at which point all saved notes will be lost. You can cancel the request any time before then.`
//   );
//   if (r == true) {
//     dbApi.markForDeletion(firebase.auth().currentUser.uid).then(() => {
//       location.reload();
//     });
//   }
// }

// function clearDeleteAccount() {
//   dbApi.clearMarkForDeletion(firebase.auth().currentUser.uid).then(() => {
//     location.reload();
//   });
// }

function initApp() {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      dbApi.getUser(user.uid).then((user) => {
        if (user.marked_for_deletion_date) {
          document.getElementById("marked-for-deletion-section").style.display =
            "unset";
          document.getElementById("active-account-section").style.display =
            "none";
        }
      });

      const name = user.email || user.name || user.displayName;
      document.getElementById("welcome-message").innerHTML = `Hi ${name},`;
    } else {
      window.location.replace("/");
    }
  });

  document
    .getElementById("notepad-btn")
    .addEventListener("click", () => window.location.replace("/"), false);

  document
    .getElementById("quickstart-sign-out")
    .addEventListener("click", signout, false);

  document
    .getElementById("quickstart-password-reset")
    .addEventListener("click", sendPasswordReset, false);

  // document.getElementById("delete-account-btn") &&
  //   document
  //     .getElementById("delete-account-btn")
  //     .addEventListener("click", deleteAccount, false);

  // document.getElementById("cancel-delete-account-btn") &&
  //   document
  //     .getElementById("cancel-delete-account-btn")
  //     .addEventListener("click", clearDeleteAccount, false);
}

export function onload() {
  initialiseFirebase();
  initApp();
}
