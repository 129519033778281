import { firebase } from "./firebase_.js";

import * as db from "./dbApi.js";
// import * as auth from "./authApi.js";

const LOCAL_STORAGE_KEY = "notepad.guru-cache";

let EDITOR = null;
let DOC = null;
let DB_LISTENER_ACTIVE = null;
let CURRENT_DOC_TS = null;
let LAST_SERVER_RECORD_TS = null;
let PENDING_WRITE_TO_SERVER = null;

let DOC_ID = null;

const DOC_UPDATE_SOURCES = {
  editor: "editor",
  storage: "storage",
  server: "server",
};

function _gtag() {
  try {
    // console.log("gtag ok");
    // console.log(arguments);
    // console.log(gtag);
    gtag(...arguments);
  } catch (err) {
    // console.log("gtag err", err);
  }
}

function throttle(callback, limit) {
  var pending = false; // Initially, we're not waiting
  let lastArguments = [];
  return function () {
    lastArguments = arguments;
    // We return a throttled function
    if (!pending) {
      pending = true; // Prevent future invocations
      setTimeout(function () {
        // After a period of time
        callback.call(null, ...lastArguments); // Execute users function
        pending = false; // And allow future invocations
      }, limit);
    }
  };
}

function saveToCache(doc) {
  // console.log("save to cache");
  if (!doc) {
    doc = DOC;
  }
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(doc));
}

function clearCache() {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
}

const throttled_saveToCache = throttle(saveToCache, 200);

function saveToServer() {
  // console.log("SAVE TO SERVER");
  const docCopy = { ...DOC };
  if (DOC_ID) {
    return db
      .updateItem(DOC_ID, docCopy)
      .then((result) => {
        PENDING_WRITE_TO_SERVER = false;
        return result;
      })
      .catch((err) => {
        console.log("Unable to sync data. Will try to save a backup:", err);
        saveBackup(docCopy);
        return err;
      });
  }
}

function saveBackup(doc) {
  // console.log("saveBackup");
  const timestamp = new Date().toISOString();
  saveLocalBackup(doc, timestamp);
  if (DOC_ID) {
    return db.createBackup(DOC_ID, doc, timestamp).catch((err) => {
      console.log("Failed to save backup to server");
      console.log(err);
    });
  }
}

// const throttled_saveToServer = throttle(saveToServer, 5000);
function throttled_saveToServer() {
  // We return a throttled function
  if (!PENDING_WRITE_TO_SERVER) {
    PENDING_WRITE_TO_SERVER = true; // Prevent future invocations
    setTimeout(function () {
      // After a period of time
      saveToServer.call();
      // .then((x) => {
      // And allow future invocations
      // console.log("savetoserver complete", x, PENDING_WRITE_TO_SERVER);
      // });
    }, 3000);
  }
}

function loadFromCache() {
  _gtag("event", "load_doc_from_cache", {
    event_category: "notepad_event",
    event_label: "load_doc_from_cache",
  });
  // console.log("LOAD FROM CACHE");
  // try {
  DOC = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  if (DOC) {
    CURRENT_DOC_TS = DOC.local_ts;
    EDITOR.value = DOC.contents;
  }
  // } catch {
  //   console.error("Failed to load local cache");
  // }
}

function saveLocalBackup(doc, timestamp) {
  const backupKeys = Object.keys(localStorage)
    .filter((key) => key.startsWith("backup"))
    .sort((a, b) => (a > b ? -1 : 1));

  const numBackups = 10;
  if (backupKeys.length > numBackups) {
    const oldBackups = backupKeys.slice(numBackups);
    oldBackups.forEach((key) => localStorage.removeItem(key));
  }

  const mostRecentKey = backupKeys[0];

  if (!timestamp) {
    timestamp = new Date().toISOString();
  }

  const newkey = `backup|${LOCAL_STORAGE_KEY}|${timestamp}`;

  const msSinceLastBackup = mostRecentKey
    ? new Date(newkey.split("|")[2]) - new Date(mostRecentKey.split("|")[2])
    : 99999;

  if (msSinceLastBackup > 30 * 1000) {
    // console.log("saveLocalBackup");
    localStorage.setItem(newkey, JSON.stringify(doc));
  }
}

const throttled_saveLocalBackup = throttle(saveLocalBackup, 1000);

function updateDoc(newDoc, source) {
  // console.log(">>> updateDoc triggered from", source);

  if (!newDoc) return;

  if (DOC && DOC.local_ts === newDoc.local_ts) {
    return;
  }
  // if (DOC) {
  //   if (DOC.local_ts > newDoc.local_ts) {
  //     updateServer();
  //     return;
  //   } else if (DOC.local_ts === newDoc.local_ts) {
  //     return;
  //   }
  // }

  if (source === DOC_UPDATE_SOURCES.editor) {
    // If new doc is more recent than current doc
    DOC = newDoc;
    CURRENT_DOC_TS = DOC.local_ts;
    updateCache();
    updateServer();
  } else if (source === DOC_UPDATE_SOURCES.storage) {
    if (!DOC || newDoc.local_ts > DOC.local_ts) {
      DOC = newDoc;
      CURRENT_DOC_TS = DOC.local_ts;
      updateEditor();
      updateServer();
    }
  } else if (source === DOC_UPDATE_SOURCES.server) {
    // If server is more recent, we update the local doc
    if (!DOC || newDoc.local_ts > DOC.local_ts) {
      DOC = newDoc;
      CURRENT_DOC_TS = DOC.local_ts;
      updateEditor();
      updateCache();
    } else if (DOC.local_ts > newDoc.local_ts) {
      updateServer();
    }
  }
}

function updateServer() {
  throttled_saveToServer();
}

function updateCache() {
  throttled_saveToCache();
}

const isFirefox = typeof InstallTrigger !== "undefined";

function updateEditor() {
  EDITOR.value = DOC.contents;

  // if (isFirefox) {
  //   EDITOR.value = DOC.contents;
  // } else {
  //   EDITOR.focus();
  //   EDITOR.selectionStart = 0;
  //   EDITOR.selectionEnd = -1;
  //   document.execCommand("insertText", false, DOC.contents);
  // }
}

function onStorageChanged(newValue) {
  // console.log("onStorageChanged");
  // const newDoc = loadFromCache();
  updateDoc(JSON.parse(newValue), DOC_UPDATE_SOURCES.storage);
}

let EDITOR_INPUT_COUNT = 0;
function onEditorInput() {
  EDITOR_INPUT_COUNT += 1;
  if (EDITOR_INPUT_COUNT === 1 || EDITOR_INPUT_COUNT % 10 === 0) {
    _gtag("event", "notepad_typing", {
      event_category: "notepad_event",
      event_label: "user_input",
      value: EDITOR_INPUT_COUNT,
    });
  }
  // console.log("onEditorInput");

  updateDoc(
    { local_ts: Date.now(), contents: EDITOR.value },
    DOC_UPDATE_SOURCES.editor
  );
}

function onServerChanged(newDoc) {
  console.log("server change detected");
  updateDoc(newDoc, DOC_UPDATE_SOURCES.server);
  LAST_SERVER_RECORD_TS = newDoc.local_ts;
}

function initialiseKeyboardShortcuts() {
  let lastManualSave = 0;

  // Block Ctrl+S
  document.onkeydown = function (e) {
    e = e || window.event; //Get event

    if (!e.ctrlKey) return;

    var code = e.which || e.keyCode; //Get key code

    switch (code) {
      case 83: //Block Ctrl+S
        e.preventDefault();
        e.stopPropagation();
        saveBackup({ ...DOC });
        break;
    }
  };
}

function initialiseNotebook() {
  EDITOR = document.getElementById("editor");
  EDITOR.readOnly = true;

  loadFromCache();

  initialiseKeyboardShortcuts();

  // saveLocalBackup();

  // throttled_saveLocalBackup(DOC);
  EDITOR.placeholder = `Welcome to Notepad Guru! A no frills, super convenient note taking application.

* Your document will automatically be saved as you type, ready for when you return.
* Want to sync notes across devices and store cloud backups? Create an account to try it for free!

Type here to get started...`;

  // On user input changes to text editor
  editor.addEventListener("input", (evt) => onEditorInput());

  // On local storage changes (triggered from other tabs/windows from the same browser)
  window.onstorage = (evt) =>
    evt.key === LOCAL_STORAGE_KEY && onStorageChanged(evt.newValue);

  // On server changes (including initial server load)
  firebase.auth().onAuthStateChanged(function (user) {
    if (!!user) {
      // TODO: detach listener when ready
      db.getUserDoc(user.uid)
        .then((doc) => (DOC_ID = doc.id))
        .then(() => {
          // saveToServer(); // Sync local data to server once before listening for updates
          DB_LISTENER_ACTIVE = true;
          db.listenToItemUpdates(
            DOC_ID,
            (value) => {
              onServerChanged(value);
              EDITOR.readOnly = false;
            },
            (error) => {
              DB_LISTENER_ACTIVE = false;
              EDITOR.readOnly = false;
            }
          );
        });
    } else {
      if (DOC && (DOC.owner || DOC.id)) {
        DB_LISTENER_ACTIVE = false;
        clearCache();
        EDITOR.value = "";
      }
      EDITOR.readOnly = false;
    }
  });

  if (!navigator.onLine) {
    EDITOR.readOnly = false;
  }
}

export {
  initialiseNotebook,
  saveToCache,
  DB_LISTENER_ACTIVE,
  CURRENT_DOC_TS,
  LAST_SERVER_RECORD_TS,
  PENDING_WRITE_TO_SERVER,
};
