import * as firebaseui from "firebaseui";
import { initialiseFirebase, firebase } from "./firebase_.js";
import { createUser } from "./dbApi.js";

const LOCAL_STORAGE_KEY = "notepad.guru-cache";

function clearCache() {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
}

function _gtag() {
  try {
    // console.log("gtag ok");
    // console.log(gtag);
    // console.log(arguments);
    gtag(...arguments);
  } catch (err) {
    // console.log("gtag err", err);
  }
}

function initFirebaseUI() {
  var uiConfig = {
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        clientId:
          "603999339058-egcvp2a035eeghmtia7gvkps44hhk94j.apps.googleusercontent.com",
      },
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,

    // TODO
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    // tosUrl: "<your-tos-url>",
    // Privacy policy url/callback.
    // privacyPolicyUrl: function () {
    //   window.location.assign("<your-privacy-policy-url>");
    // },
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        if (authResult.additionalUserInfo.isNewUser) {
          _gtag("event", "sign_up");
        } else {
          _gtag("event", "login");
        }
        clearCache();
        createUser(authResult.user).then(() => window.location.replace("/"));
        return false;
      },
    },
  };

  var ui = new firebaseui.auth.AuthUI(firebase.auth());
  ui.disableAutoSignIn();
  ui.start("#firebaseui-auth-container", uiConfig);
}

function initApp() {
  initFirebaseUI();
}

export function onload() {
  initialiseFirebase();
  initApp();
}
