import { initialiseFirebase, firebase } from "./firebase_.js";
import * as utils from "./utils.js";

function initialiseForm() {
  const emailInputEl = document.getElementById("email-input");
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const email = user.toJSON().email || user.toJSON().providerData[0].email;

      emailInputEl.value = email;
      emailInputEl.readOnly = true;
    }
  });
}
export function onload() {
  utils.initResizeListener();
  initialiseFirebase();
  initialiseForm();
}
