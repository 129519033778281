// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase_ from "@firebase/app";

const firebase = firebase_.firebase;

// // If you enabled Analytics in your project, add the Firebase SDK for Analytics
// import "firebase/analytics";

// Add the Firebase products that you want to use
import "@firebase/auth";
import "@firebase/firestore";

let db;

function initialiseFirebase() {
  // TODO: Replace the following with your app's Firebase project configuration
  const firebaseConfig = {
    apiKey: "AIzaSyDDAPQNY_7odf02oAxlBi033VSqXL_y2jc",
    authDomain: "notepad-guru.firebaseapp.com",
    projectId: "notepad-guru",
    databaseURL: "https://notepad-guru.firebaseio.com",
  };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  db = firebase.firestore();
}

export { initialiseFirebase, firebase, db };
