"use strict";

import { initialiseFirebase, firebase } from "./js/firebase_.js";
import * as status from "./js/status.js";
import * as utils from "./js/utils.js";
import * as dbApi from "./js/dbApi.js";

import * as notebookPage from "./js/notebook.js";
import * as signinPage from "./js/signin.js";
import * as accountPage from "./js/account.js";
import * as revisionsPage from "./js/revisions.js";
import * as helpPage from "./js/help.js";

function initialiseServiceWorker() {
  if ("serviceWorker" in navigator) {
    console.log("CLIENT: service worker registration in progress.");
    navigator.serviceWorker.register("./service-worker.js");
  }

  // if ("serviceWorker" in navigator) {
  //   navigator.serviceWorker.getRegistrations().then(function (registrations) {
  //     for (let registration of registrations) {
  //       registration.unregister();
  //     }
  //   });
  // }
}

function handleOneTapLogin(authResponse) {
  const credential = firebase.auth.GoogleAuthProvider.credential(
    authResponse.credential
  );

  firebase
    .auth()
    .signInWithCredential(credential)
    .then((authResult) => {
      dbApi.createUser(authResult.user).then(() => {
        window.location.replace("/");
      });
    });
}

function initOneTapLogin() {
  window.handleOneTapLogin = handleOneTapLogin;

  // Load the one tap login dialog only if no user is logged in
  firebase.auth().onAuthStateChanged((user) => {
    if (!user) {
      // Adding the script tag to the head as suggested before
      const head = document.head;
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://accounts.google.com/gsi/client";

      // This is some hacky stuff to avoid the one tap sign in iframe from stealing
      // focus from the text editor
      const callback = () => {
        setTimeout(() => {
          const oneTapEl = document.getElementById(
            "credential_picker_container"
          );
          if (oneTapEl) {
            oneTapEl.style.display = "inline";

            document.getElementById("editor").onblur = (x) => {
              setTimeout(() => {
                document.getElementById("editor").focus();
              }, 20);
            };
            setTimeout(
              () => (document.getElementById("editor").onblur = null),
              500
            );
          }
        }, 2000);
      };

      script.onreadystatechange = callback;
      script.onload = callback;

      head.appendChild(script);
    }
  });
}

function onNotebookLoad() {
  utils.initResizeListener();
  initialiseServiceWorker();
  status.initialiseStatusInfo();
  initialiseFirebase();
  notebookPage.initialiseNotebook();
  initOneTapLogin();
}

function onload() {
  let loadFunction = null;

  if (window.location.pathname.startsWith("/signin")) {
    loadFunction = signinPage.onload;
  } else if (window.location.pathname.startsWith("/account")) {
    loadFunction = accountPage.onload;
  } else if (window.location.pathname.startsWith("/revisions")) {
    loadFunction = revisionsPage.onload;
  } else if (window.location.pathname.startsWith("/help")) {
    loadFunction = helpPage.onload;
  } else {
    loadFunction = onNotebookLoad;
  }

  if (
    document.readyState === "complete" ||
    document.readyState === "loaded" ||
    document.readyState === "interactive"
  ) {
    loadFunction();
  } else {
    document.addEventListener("DOMContentLoaded", function () {
      loadFunction();
    });
  }
}

onload();
